// External Dependencies
import { Link } from "@remix-run/react";

const Button = ({
  children,
  disabled = false,
  external = false,
  fullWidth = false,
  href = "",
  icon = null,
  iconLeft = true,
  onClick = null,
  primary = false,
  ghost = false,
  form = null,
  size = "sm",
  state = null,
  styles = "",
  buttonStyles = "",
  textButton = false,
  type = "button",
  deleteButton = false,
  shareButton = false,
  ...props
}) => {
  const isPrimary = primary && !textButton;
  const isTextButton = !primary && textButton;
  const isGhostButton = ghost && !primary && !textButton;

  const bgColorClasses = isPrimary
    ? `${
        disabled
          ? "bg-primary-300"
          : "bg-primary-600 hover:bg-primary-700 transition-colors duration-300"
      }`
    : isTextButton
      ? `${
          disabled
            ? "bg-gray-200"
            : "transition-colors duration-300 hover:bg-gray-50"
        }`
      : deleteButton
        ? "border border-red-50 bg-red-50 text-red-700 hover:bg-red-200 hover:border-red-200 transition-colors duration-300 "
        : shareButton
          ? "bg-primary-50 text-primary-700 bg-opacity-75 shadow-none hover:text-primary-800 hover:bg-opacity-100 transition-colors duration-300 "
          : isGhostButton
            ? "bg-transparent hover:bg-gray-50 border border-transparent hover:border-gray-300 transition-colors duration-300 "
            : `bg-white ${
                disabled
                  ? ""
                  : "hover:bg-gray-50 border hover:border-gray-200 border-gray-300 transition-colors duration-300 "
              }`;

  const disabledClasses = disabled ? "cursor-auto opacity-80" : "";
  const paddingClasses =
    size === "sm" ? `px-2 py-1.5` : size === "md" ? "px-5 py-2" : "px-28 py-2";

  const textColorClasses = isPrimary
    ? "text-white"
    : `${disabled ? "text-gray-300" : "text-gray-600"}`;

  const textSizeClasses = size === "sm" ? "text-sm" : "text-md";
  const widthClasses = fullWidth ? "w-full" : "";
  const shadowClasses = isTextButton
    ? ""
    : `${isGhostButton ? "hover:" : ""}shadow-sm`;

  const roundedClasses = size === "sm" ? "rounded-md" : "rounded-lg";

  const buttonClasses = [
    "font-semibold flex items-center transition-all justify-center focus:outline-none relative focus:ring-1 focus:ring-gray-300 focus:ring-offset-2",
    bgColorClasses,
    roundedClasses,
    disabledClasses,
    paddingClasses,
    textColorClasses,
    textSizeClasses,
    widthClasses,
    shadowClasses,
    buttonStyles,
  ];

  if (href) {
    return external ? (
      <div className={`${styles} ${icon ? "flex" : ""}`}>
        <a
          href={href}
          className={buttonClasses.join(" ")}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => {
            if (onClick) onClick(event);
          }}
          {...props}
        >
          {icon && iconLeft ? <div className="absolute h-6 w-6">{icon}</div> : null}
          {children}
          {icon && !iconLeft ? <div className="absolute right-0 top-1/2 h-6 w-6 -translate-y-1/2 transform">
              {icon}
            </div> : null}
        </a>
      </div>
    ) : (
      <div className={`${styles} ${icon ? "flex" : ""}`}>
        <Link to={href} prefetch="intent" state={state} {...props}>
          <button
            className={buttonClasses.join(" ")}
            disabled={disabled}
            onClick={onClick}
            type={type}
            form={form}
          >
            {icon && iconLeft ? <div className="mr-1 h-6 w-6">{icon}</div> : null}
            {children}
            {icon && !iconLeft ? <div className="ml-1 h-6 w-6">{icon}</div> : null}
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className={`${styles} ${icon ? "flex" : ""}`}>
      <button
        className={buttonClasses.join(" ")}
        disabled={disabled}
        onClick={onClick}
        type={type}
        form={form}
        {...props}
      >
        {icon && iconLeft ? <div className="mr-1 h-6 w-6">{icon}</div> : null}
        {children}
        {icon && !iconLeft ? <div className="ml-1 h-6 w-6">{icon}</div> : null}
      </button>
    </div>
  );
};

export default Button;
